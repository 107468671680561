.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #282c34; */
}

.container {
  width: 50vw;
  padding: 0px 30px 0px 20px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: gray; */
}

.container-upload {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  /* background-color: gray; */
}

.container-option {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  /* background-color: gray; */
}

.container-row-wrap {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;

  /* background-color: gray; */
}
.cardList {
  width: 100px;
  height: 100px;
  margin: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s linear;
  transform: scale(1);
  opacity: 0.6;
}
.cardList:hover {
  transform: scale(1.1);
  opacity: 1;
  cursor: pointer;
  box-shadow: 0px 6px 15px #00000059;
}
.cardList img {
  width: 100%;
  position: relative;
  object-fit: cover;
  z-index: 1;
}
.cardList-Layer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(128, 128, 128, 0.1);
}

.card {
  height: 90%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  box-shadow: 0px 6px 30px #00000059;
  /* background-color: #0000002c; */
}
.card-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.card-placeholder p {
  margin: 2px 0px;
  padding: 0px;
  color: #3c3c3ce5;
  font-size: 23px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}

.card-placeholder img {
  width: 100px;
  height: 100px;
  margin: 20px;
}

.card-placeholder p:nth-child(3) {
  color: #3c3c3cd5;
  font-size: 17px;
  font-weight: 300;
}


.card-layer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}
.card-layer p {
  color: #ffffff;
  font-size: 20px;
}

.card img {
  height: 100%;
  position: relative;
  object-fit: fill;
  z-index: 1;
}

.borderbox {
  display: flex;
  position: absolute;
  z-index: 3;
  border: 3px solid rgb(165, 42, 42);
  border-radius: 30px 30px 30px 0px;
}

.borderbox-label {
  width: auto;
  padding: 10px;
  text-align: center;
  margin-bottom: 0px;
  position: absolute;
  bottom: -37px;
  left: -3px;
  border-radius: 0px 0px 5px 0px;
  color: aliceblue;
  font-size: 13px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background-color: brown;
}

.borderbox-score {
  width: auto;
  padding: 5px 10px;
  text-align: center;
  margin-bottom: 0px;
  position: absolute;
  bottom: -55px;
  left: -3px;
  border-radius: 5px 5px 5px 5px;
  color: #3c3c3c;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ffffff;
}

.upload-button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
  border-radius: 8px;
  background-color: rgba(165, 42, 42, 1);
  transition: all 0.2s linear;
  cursor: pointer;
  overflow: hidden;
}
.upload-button img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
.upload-button:hover {
  background-color: rgba(199, 52, 52, 1);
}
.button-layer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgb(235, 232, 232);
}

.spinner {
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 14px;
  height: 14px;
  background-color: brown;
  margin: 3px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
